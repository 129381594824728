<template>
  <!--begin::Card-->
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">
      Delivery / Collection Times <br />
      <small
        >Delivery times are displayed to the customer once they have placed an
        order on your website. This is the amount of time until their order will
        be delivered or ready to collect. Below you can set the amount of time
        in minutes for delivery and collection.</small
      >
    </h4>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Average Delivery Time</label>
          <span class="form-text text-muted"
            >Enter the average time deliveries take.</span
          >
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Monday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="d_mon" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Tuesday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="d_tue" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Wednesday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="d_wed" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Thursday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="d_thu" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Friday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="d_fri" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Saturday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="d_sat" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Sunday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="d_sun" :options="timings"></b-form-select>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Average Collection Time</label>
          <span class="form-text text-muted"
            >Enter the average time Collections take.</span
          >
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Monday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="c_mon" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Tuesday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="c_tue" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Wednesday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="c_wed" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Thursday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="c_thu" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Friday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="c_fri" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Saturday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="c_sat" :options="timings"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 10px">
            <b-col sm="3">
              <p>Sunday</p>
            </b-col>
            <b-col sm="4">
              <b-form-select v-model="c_sun" :options="timings"></b-form-select>
            </b-col>
          </b-row>
        </div>
      </div>
      <hr />
      
    </div>
    <br>
   <div class="form-group" style="float: right;margin-top:2%">
        <!-- <b-button @click="save">Save</b-button> -->
        <button
          style="float: right;"
          v-on:click="save"
          class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
          data-wizard-type="action-submit"
        >
          Submit
        </button>
      </div>
  </div>
  <!--end::Card-->
</template>

<script>
import {
  DELIVERY_COLLECTION_TIMINGS,
  GET_DELIVERY_COLLECTION_TIMINGS
} from "@/core/services/store/config.module";
import Swal from "sweetalert2";
export default {
  name: "DeliveryTime",
  data() {
    return {
      timings: [
        "15 - Minutes",
        "30 - Minutes",
        "45 - Minutes",
        "1 - Hour",
        "1.5 - Hour"
      ],
      delivery: [],
      collection: [],
      d_mon: "",
      d_tue: "",
      d_wed: "",
      d_thu: "",
      d_fri: "",
      d_sat: "",
      d_sun: "",
      c_mon: "",
      c_tue: "",
      c_wed: "",
      c_thu: "",
      c_fri: "",
      c_sat: "",
      c_sun: ""
    };
  },
  methods: {
    save() {
      this.delivery[0].timing = this.d_mon;
      this.delivery[1].timing = this.d_tue;
      this.delivery[2].timing = this.d_wed;
      this.delivery[3].timing = this.d_thu;
      this.delivery[4].timing = this.d_fri;
      this.delivery[5].timing = this.d_sat;
      this.delivery[6].timing = this.d_sun;
      this.collection[0].timing = this.c_mon;
      this.collection[1].timing = this.c_tue;
      this.collection[2].timing = this.c_wed;
      this.collection[3].timing = this.c_thu;
      this.collection[4].timing = this.c_fri;
      this.collection[5].timing = this.c_sat;
      this.collection[6].timing = this.c_sun;

      this.$store
        .dispatch(DELIVERY_COLLECTION_TIMINGS, {
          delivery: this.delivery,
          collection: this.collection,
          signup : 1
        })
        // go to which page after successfully login
        .then(resp => {
          // this.form.orders = resp.data;
          if (resp.status === "Success") {
            if  (this.$route.name === 'wizard-delivery-collection-details') {
              Swal.fire("Success", "Delivery Collections details updated successfully", "success");
            } else {
              Swal.fire("Information!", "Wizard completed successfully", "success");
              return;
            }
          } else {
            Swal.fire("Error", resp.message, "error");
            return;
          }
          // this.$router.push({ name: "dashboard" })
        })
        .catch(error => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
          }
          console.log(error);
        });
    },
    cancel() {
      //
    },
    async getDeliveryCollectionTimings() {
      this.$store
        .dispatch(GET_DELIVERY_COLLECTION_TIMINGS)
        .then(resp => {
          if (resp.length > 0) {
            for (let i = 0; i < resp.length; i++) {
              if (resp[i].type == "Delivery") {
                if (resp[i].day_of_week === "Monday") {
                  this.d_mon = resp[i].time;
                  this.delivery[0].id = resp[i].id;
                } else if (resp[i].day_of_week === "Tuesday") {
                  this.d_tue = resp[i].time;
                  this.delivery[1].id = resp[i].id;
                } else if (resp[i].day_of_week === "Wednesday") {
                  this.d_wed = resp[i].time;
                  this.delivery[2].id = resp[i].id;
                } else if (resp[i].day_of_week === "Thursday") {
                  this.d_thu = resp[i].time;
                  this.delivery[3].id = resp[i].id;
                } else if (resp[i].day_of_week === "Friday") {
                  this.d_fri = resp[i].time;
                  this.delivery[4].id = resp[i].id;
                } else if (resp[i].day_of_week === "Saturday") {
                  this.d_sat = resp[i].time;
                  this.delivery[5].id = resp[i].id;
                } else if (resp[i].day_of_week === "Sunday") {
                  this.d_sun = resp[i].time;
                  this.delivery[6].id = resp[i].id;
                }
              } else {
                if (resp[i].day_of_week === "Monday") {
                  this.c_mon = resp[i].time;
                  this.collection[0].id = resp[i].id;
                } else if (resp[i].day_of_week === "Tuesday") {
                  this.c_tue = resp[i].time;
                  this.collection[1].id = resp[i].id;
                } else if (resp[i].day_of_week === "Wednesday") {
                  this.c_wed = resp[i].time;
                  this.collection[2].id = resp[i].id;
                } else if (resp[i].day_of_week === "Thursday") {
                  this.c_thu = resp[i].time;
                  this.collection[3].id = resp[i].id;
                } else if (resp[i].day_of_week === "Friday") {
                  this.c_fri = resp[i].time;
                  this.collection[4].id = resp[i].id;
                } else if (resp[i].day_of_week === "Saturday") {
                  this.c_sat = resp[i].time;
                  this.collection[5].id = resp[i].id;
                } else if (resp[i].day_of_week === "Sunday") {
                  this.c_sun = resp[i].time;
                  this.collection[6].id = resp[i].id;
                }
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    refresh() {
      for (let i = 0; i < 7; i++) {
        this.delivery[i] = { id: 0, timing: "" };
        this.collection[i] = { id: 0, timing: "" };
      }
    }
  },
  mounted() {
    this.refresh();
    this.getDeliveryCollectionTimings();
  }
};
</script>
